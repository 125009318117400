<template>
  <div>
    <!-- news.html 12-40 -->
    <!--面包屑导航-->
    <div class="breadcrumb">
      <div class="container">
        <h2>
          <a href>首页</a>&gt;
          <a href>公司动态</a>
        </h2>
      </div>
    </div>
    <!--页面主体-->
    <!-- 网络请求的数据是异步的: 在未加载完成前不应该使用, 否则后台会报错! -->
    <div class="main container" v-if="data">
      <div class="news">
        <ul>
          <li v-for="(item, index) in data.data" :key="index">
            <span>{{ item.pubTime | date }}</span>
            <!-- 
              :to 全称:  v-bind:to="js代码"
            -->
            <router-link :to="`/news_details/${item.nid}`">{{ item.title }}</router-link>
          </li>
          <!--<li><span>2016-02-21</span><a href="">净美仕新风净化系统　助力校园新风行动</a></li>-->
          <!--<li><span>2016-02-21</span><a href="">全国新风行动全面启动 助推净美仕战略升级</a></li>-->
          <!--<li><span>2016-02-21</span><a href="">智能空气净化器翻盘：净美仕能否领衔?</a></li>-->
        </ul>
      </div>
      <!-- 分页导航-->
      <div class="pages">
        <a @click="getData(data.pageNum - 1)" v-if="data.pageNum > 1">上一页</a>
        <a class="default" v-else>上一页</a>
        <!-- :class="{样式名: true/false}" -->
        <a
          @click="getData(item)"
          :class="{ cur: item == data.pageNum }"
          v-for="(item, index) in data.pageCount"
          :key="index"
        >{{ item }}</a>
        <!-- <a href="">2</a> -->
        <!--<a href="">3</a>-->
        <!--<a href="">4</a>-->
        <!--<a href="">5</a>-->
        <a @click="getData(data.pageNum + 1)" v-if="data.pageNum < data.pageCount">下一页</a>
        <a class="default" v-else>下一页</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    getData(pno) {
      let url = "news_select.php?pageNum=" + pno;

      this.axios
        .get(url)
        .then((res) => {
          console.log(res);
          this.data = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  // 过滤器:  {{ xxx | filter }}
  filters: {
    date(timestamp) {
      // 转化时间戳为: 年-月-日
      let date = new Date(parseInt(timestamp));

      let year = date.getFullYear();
      let month = date.getMonth() + 1; //0-11 转 1-12
      let day = date.getDate();

      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style>
/* 页数: 双击不能选中 */
.pages {
  user-select: none;
  cursor: pointer;
}
</style>
